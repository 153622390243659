<template>
  <div class="z-50 fixed inset-0">
    <div class="fixed inset-0 bg-black/70"></div>
    <div class="fixed w-full h-full flex items-center justify-center">
      <div class="px-8 py-6 w-full bg-white rounded-lg sm:max-w-lg max-h-[90vh] overflow-y-auto">
        <div><slot name="header" /></div>
        <slot />
        <div><slot name="footer" /></div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style></style>
