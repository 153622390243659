const currencies = {
  usd: '$',
  ngn: '₦',
  eur: '€',
  zar: 'R',
  egp: 'E£',
  cad: 'C$',
  kes: 'Ksh',
  gbp: '£',
  aoa: 'Kz',
  ghs: 'GH₵',
  tzs: 'TSh',
};

export const currencySymbol = (currency) => {
  return !currency ? '' : currencies[currency.toLowerCase()];
};

export const formatNumber = (amount) => {
  return parseFloat(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const getDifferenceInDays = (createdDate) => {
  const date1 = new Date(createdDate);
  const date2 = new Date();
  const diffInMs = Math.abs(date2 - date1);
  return parseInt(diffInMs / (1000 * 60 * 60 * 24));
};

export const formatTitle = (value) => {
  const result = value.split(' ').join('-');
  return result.toLowerCase().replaceAll('/', '-');
};

export const getCookie = (cname) => {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const role = (job) => job.role_title?.replaceAll('/', '-') || job.job.title_name?.replaceAll('/', '-');

export const createJobLink = (job) => {
  const company_name = job.employer.name.toLowerCase().replaceAll(' ', '-');
  const title = role(job)?.toLowerCase().replaceAll(' ', '-');
  const job_link = `/${company_name}/${job.fid}/${title.replaceAll('/', '-')}`;
  return job_link;
};
